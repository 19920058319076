import $ from 'jquery'

function filterLibrary(loadMore) {
    const libraryListWrapper = $('.library__table-wrapper')
    const libraryList = $('.library__table')
    if(loadMore == false) {
        localStorage.curr_page = 0
        localStorage.search = ''
        localStorage.perPage = 8 // standardized posts_per_page count to reliably use paging
    } 

    const section = $('.js-radio-section.is-active').attr('id');
    const cat = $('.js-radio-category.is-active').attr('id');
    const search_term = $('.js-filter-search').val();
    const curr_letter = localStorage.getItem('curr_letter');
   
    var category = section;
    if(cat != 'all-categories') {
        category = cat;
    }
    console.log(section);
    // console.log('new cat:' + category)
    // console.log('new search_term:' + search_term)
    const newQuery = ((localStorage.category !== category) || (localStorage.search !== search_term))
    const curr_page = ++localStorage.curr_page;
    // console.log('new query:' + newQuery)

    localStorage.category = category
    localStorage.search = search_term
    localStorage.curr_page = curr_page
    localStorage.curr_letter = curr_letter

   /*  console.log('changed cat:' + localStorage.category)
    console.log('changed search_term:' + localStorage.search)
    console.log('changed curr_page:' + localStorage.curr_page)
    console.log('changed curr_letter:' + localStorage.curr_letter) */

    console.log(category, search_term, curr_page, curr_letter);

    $.ajax({
        url : ajax_object.ajax_url,
        type : 'POST',
        data : {
            category,
            search_term,
            curr_page,
            curr_letter,
            action:'libraryfilter'
        },
        beforeSend : function(xhr){
            if(newQuery) {
                $('.library__table-wrapper > .library__table').empty();
            }
            $('.library__table-wrapper').addClass('loading');
            $('.js-load-more-library').hide();
        },
        success : function( res ){
            var count = (res.match(/library__table-body-inner/g) || []).length;
            if (count) {

                console.log('ukupno postova: ' + count)
                console.log('localStorage.max_pages:' + parseInt(localStorage.max_pages))
                console.log('localStorage.curr_page:' + parseInt(localStorage.curr_page))
                if(res.includes('No library articles found!') || (parseInt(localStorage.max_pages) <= parseInt(localStorage.curr_page))) {
                    $('.js-load-more-library').hide();
                } else {
                    $('.js-load-more-library').show();
                }

                $('.library__table-wrapper > .library__table-body').remove();

                $('.library__table-wrapper > .library__table').append(res)
                $('.library__table-wrapper').removeClass('loading');
                $('.js-load-more-library').hide();

                if((parseInt(localStorage.max_pages) < parseInt(localStorage.curr_page)) || (count < 8)) {
                    $('.library__table-wrapper > .library__table').append('<h6 class="u-text-center u-color-dark-blue u-mt3">No more articles found!</h6>')
                }
            } 
            else{
                $('.library__table-wrapper > .library__table').append('No results found');
                $('.library__table-wrapper').removeClass('loading');
            }
        }
    }) 
}

function populateCatFilter(category) {
  
    $.ajax({
        url : ajax_object.ajax_url,
        type : 'POST',
        data : {
            category,
            action:'librarycats'
        },
        beforeSend : function(xhr){
            $('.js-filter-category-dropdown form').empty();
        },
        success : function( res ){
            if (res.length) {
                $('.js-filter-category-dropdown form').html(res)  
            } 
        }
    })
}

function initLibrary() {
    localStorage.curr_page = 1
    localStorage.category = 'all-sections'
    localStorage.search = ''
    localStorage.curr_letter = 'A'
    localStorage.perPage = 8

    $(document).on('submit', '.js-filter-section-selected', function(event) {
        $(this).toggleClass('is-active');
        $('.js-filter-section-dropdown').toggleClass('is-active');
    })

    $(document).on('click', '.js-filter-section-selected', function(event) {
        $(this).toggleClass('is-active');
        $('.js-filter-section-dropdown').toggleClass('is-active');
    })

    $(document).on('submit', '.js-filter-category-selected', function(event) {
        $(this).toggleClass('is-active');
        $('.js-filter-category-dropdown').toggleClass('is-active');
    })

    $(document).on('click', '.js-filter-category-selected', function(event) {
        $(this).toggleClass('is-active');
        $('.js-filter-category-dropdown').toggleClass('is-active');
    })

    $(document).on('click', '.js-radio-section', function(event) {
        $('.js-radio-section').removeClass('is-active')
        $(this).addClass('is-active')
        var parentCatName = $(this).data('name')
        var parentCatID = $(this).attr('id')
        $('.js-filter-section-selected').text(parentCatName)
        $('.js-filter-section-dropdown').toggleClass('is-active')
        $('.js-filter-section-selected').toggleClass('is-active')
        $('.js-filter-category-selected').text('All categories')
        populateCatFilter(parentCatID)
    })
    $(document).on('click', '.js-radio-category', function(event) {
        $('.js-radio-category').removeClass('is-active')
        $(this).addClass('is-active')
        var parentCatName = $(this).data('name')
        $('.js-filter-category-selected').text(parentCatName)
        $('.js-filter-category-dropdown').toggleClass('is-active')
        $('.js-filter-category-selected').toggleClass('is-active')
    })
    $(document).on('click', '.js-filter-button', function(event) {
        event.preventDefault()
        filterLibrary(false)
        $('.js-filter-category-dropdown').removeClass('is-active')
        $('.js-filter-section-dropdown').removeClass('is-active')
    })

    $(".js-filter-search").on('keyup', function (e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            console.log('enter')
            event.preventDefault()
            filterLibrary(false)
            $('.js-filter-category-dropdown').removeClass('is-active')
            $('.js-filter-section-dropdown').removeClass('is-active')
        }
    });

    $(document).on("click",".js-load-more-library",function(event) {
        event.preventDefault()
        filterLibrary(true)
    });
}

initLibrary()
